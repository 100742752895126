import { useState } from "react";
import ImageCarousel from "./ImageCarousel";

type LogoProp = string[];

interface SponsorsProps {
  logos: LogoProp[];
  style?: React.CSSProperties;
}

export default function Sponsors(props: SponsorsProps) {
  const [useCarousel] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setUseCarousel(window.outerWidth < 768);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  if (useCarousel) {
    return <ImageCarousel images={props.logos.flat()} width="50%" />;
  } else {
    return (
      <div style={props.style}>
        <h1 style={styles.title}>Sponsored by</h1>
        <div style={styles.sponsorsContainer}>
          {props.logos.map((logoRow, rowIndex) => (
            <div key={rowIndex} style={styles.logoRow}>
              {logoRow.map((logo, index) => (
                <img
                  key={index}
                  style={{ width: `${rowIndex === 3 ? 10 : rowIndex === 2 ? 12 : (40 / logoRow.length - rowIndex * 0.25)}%`, minWidth: '100px' }}
                  src={logo}
                  alt={rowIndex + "-" + index}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  title: {
    fontSize: "32px",
    fontWeight: 700,
    textAlign: "center" as "center",
  },
  sponsorsContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    gap: "70px",
    marginLeft: "10px",
    marginRight: "10px"
  },
  logoRow: {
    display: "flex",
    alignItems: "center",
    gap: "36px",
    flexWrap: "wrap" as "wrap",
    justifyContent: "center"
  },
};
