import { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Switch from './components/Switch';
import GenevEventTab from './tabs/GenevEventTab';
import ZurichEventTab from './tabs/ZurichEventTab';
import LuganoEventTab from './tabs/LuganoEventTab';

type TabGenerator = () => React.JSX.Element;
interface Tab {
  tabName: string;          // Name of the tab (displayed in the switch component)
  mapUrl: string;
  tabTitle?: string;        // Header title of the tab, if undefined, the tabName will be used
  registerUrl?: string;     // URL to register to the event (for the register button in the header), if undefined, the button will not be displayed
  generator: TabGenerator;  // Function that generates the tab content
}

function App() {
  // Declare tabs here
  //  key: tab name (will be displayed in the switch component)
  //  value: tab generator function (= a function that returns a JSX element)
  const tabs: Record<string, Tab> = {
    'Geneva event': {
      tabName: 'Geneva event',
      tabTitle: "Genev’Hack",
      mapUrl: "./palexpo-plan.pdf",
      registerUrl: "https://infomaniak.events/en-ch/conferences/genevhack-2025/90096f3d-505f-44b2-bbe2-192861842b67/event/1370148",
      generator: GenevEventTab,
    },
    'Zurich event': {
      tabName: 'Zurich\' event',
      tabTitle: "Limmat’Hack",
      mapUrl: "./limmathack-map.png",
      registerUrl: "https://infomaniak.events/en-ch/conferences/limmathack-2025/6454ff08-17ce-4eca-a663-a99e412d8415/event/1370154",
      generator: ZurichEventTab,
    },
    'Lugano event': {
      tabName: 'Lugano\' event',
      tabTitle: "Lugano’Hack",
      mapUrl: "./limmathack-map.png",
      registerUrl: "https://infomaniak.events/en-ch/conferences/hackmaggiore-2025/1b3f98b9-d773-4a9d-b5f3-ea8b30b74479/event/1370157",
      generator: LuganoEventTab,
    },
  };

  /**
   * Quick and dirty solution for selectiing the right tab depending on the url.
   */
  let locationIndex = "Geneva event";
  let selectedIndex = 0; 
  const currentUrl = window.location.href;
  if (currentUrl.includes("genevhack.ch")) {
    locationIndex = "Geneva event";
  } else if (currentUrl.includes("limmathack.ch")) {
    locationIndex = "Zurich event";
    selectedIndex = 1;
  } else if (currentUrl.includes("hackmajor.ch")) {
    locationIndex = "Lugano event";
    selectedIndex = 2;
  } /*else if(currentUrl.includes("localhost")) {
    console.log("dev");
    locationIndex = "Geneva event";
  }*/

  const [selectedTab, setSelectedTab] = useState(locationIndex);
  
  return (
    <>
      <Header
        title={tabs[selectedTab].tabTitle ?? tabs[selectedTab].tabName}
        registerUrl={tabs[selectedTab].registerUrl}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='switch-container'>
          <Switch
            selectedIndex={selectedIndex}
            choices={Object.keys(tabs)}
            onSelect={(index) => setSelectedTab(Object.keys(tabs)[index])}
          />
        </div>
      </div>
      <main>
        {tabs[selectedTab].generator()}
      </main>
    </>
  );
}

export default App;
