interface Organizer {
    src: string;
    name?: string; // used as alt text 
}

interface OrganizersProps {
    organizers: Organizer[];
}

/*
    This components takes an array of organizers logos.
    The organizersName array is optional, but if supplied, it should have the same length as the organizers array.
    The styles array is optional, but if supplied, it should have the same length as the organizers array,
    and each element of the styles array will be applied to the corresponding organizer logo.
*/

export default function Organizers(props: OrganizersProps) {
    return (
        <>
            <h2>Organized by</h2>
            <div style={styles.container}>
                {props.organizers.map((organizer, index) => (
                    <img
                        key={index}
                        style={styles.images}
                        src={organizer.src}
                        alt={organizer.name}
                        width="1000"
                    />
                ))}
            </div>
            <div>
                Initially powered by terreActive & Hacknowledge
            </div>
        </>
    );
}

const styles = {
    title: {
        fontSize: "32px",
        fontWeight: 100,
        textAlign: "center" as "center",
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
    },
    images: {
        maxWidth: "25%",
    },
};