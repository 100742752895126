import ButtonLink from "./ButtonLink";
import Logo from "./Logo";

interface HeaderProps {
  title: string;
  registerUrl?: string; // if undefined, the button will not be displayed
}

export default function Header(props: HeaderProps) {
  return (
    <header>
      <div className="logo-text">
        <Logo />
        <h1>{props.title}</h1>
      </div>
      {props.registerUrl && (
        <ButtonLink
            href={props.registerUrl}
            newTab={true}
            style={{ maxWidth: "161px" }}
        >
            Register Now
        </ButtonLink>
      )}
    </header>
  );
}
